import { graphql, navigate } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import React, { Fragment, useEffect } from "react"
import { Helmet } from "react-helmet"
import rehypeReact from "rehype-react"
import styled from "styled-components"
import Container from "../components/Container"
import { LoginStateContextProvider } from "../contexes/LoginStateContext"
import PagesContext from "../contexes/PagesContext"
import getNamedPartials from "../partials"
import { getCachedUserDetails, loggedIn } from "../services/moocfi"
import Layout from "./Layout"
import "./remark.css"
const ContentWrapper = styled.div`
  margin-top: 1rem;
  padding-bottom: 2rem;

  p {
    margin-bottom: 2rem;
  }
`

const Title = styled.h1``

const CoursePartOverviewTemplate = (props) => {
  const { language } = useI18next()
  useEffect(() => {
    if (!loggedIn()) {
      return
    }

    async function getUserInfo() {
      let userInfo = await getCachedUserDetails({ language })
      const student_language = userInfo?.extra_fields?.language
      if (student_language === undefined) {
        navigate("/missing-info")
      }
    }
    getUserInfo()
  }, [language])
  const { data } = props
  const { frontmatter, htmlAst } = data.page
  const allPages = data.allPages.edges.map((o) => {
    const res = o.node?.frontmatter
    res.exercises = o.node?.moocfiExercises
    return res
  })
  const partials = getNamedPartials()
  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: partials,
  }).Compiler

  const filePath = data.page.fileAbsolutePath.substring(
    data.page.fileAbsolutePath.lastIndexOf("/data/"),
    data.page.fileAbsolutePath.length,
  )
  return (
    <PagesContext.Provider
      value={{
        all: allPages,
        current: {
          frontmatter: frontmatter,
          filePath: filePath,
          language,
        },
      }}
    >
      <Helmet title={frontmatter.title} />
      <LoginStateContextProvider>
        <Layout>
          <Fragment>
            <Container>
              <ContentWrapper>
                <Title>{frontmatter.title}</Title>
                {renderAst(htmlAst)}
              </ContentWrapper>
            </Container>
          </Fragment>
        </Layout>
      </LoginStateContextProvider>
    </PagesContext.Provider>
  )
}

export default CoursePartOverviewTemplate

export const pageQuery = graphql`
  query ($path: String!, $language: String!) {
    page: markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      html
      frontmatter {
        path
        title
      }
      fileAbsolutePath
    }
    allPages: allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            path
            title
          }
          moocfiExercises {
            id
            type
            parentPagePath
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
